<template>
  <v-container>
        <v-row class="mb-10" justify="center">
            <v-col cols="12" sm="12" md="3" class="text-center">
                <v-img class="mx-auto" src="~@/assets/img/home_preguntas.png"></v-img>
            </v-col>
        </v-row>
        
        <v-row class="mb-10" justify="center">
            <v-col cols="12" sm="12" md="1"></v-col>
            <v-col cols="12" sm="12" md="3" class="text-center">
                <v-img class="mx-auto" max-width="400" src="~@/assets/img/question_mark.png"></v-img>
            </v-col>
            <v-col 
                v-if="itemsHome.faq_questions"
                cols="12"
                sm="12"
                md="7"
                class="text-center"
            >
                <v-expansion-panels inset class="pb-5">
                    <v-expansion-panel
                        v-for="(item,i) in itemsHome.faq_questions[0].children.slice(0, 3)"
                        :key="i"
                    >
                        <v-expansion-panel-header>
                            {{ item.title }}
                            <template v-slot:actions>
                                <v-icon color="dark">
                                mdi-plus
                                </v-icon>
                            </template>
                        </v-expansion-panel-header>

                        <v-expansion-panel-content>
                            <div class="text-left" v-html="item.description"></div>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
                <v-btn
                    dark
                    rounded
                    color="secondary"
                    text
                    :loading="loading"
                    to="/faq"
                >
                    Ver todas las preguntas
                </v-btn>
            </v-col>
            <v-col cols="12" sm="12" md="1"></v-col>
        </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
export default {
    data: () => ({
    }),
    computed: {
        ...mapState("loader", ["loading"]),
        ...mapState("landing", ["itemsHome"]),
    },
}
</script>

<style>

</style>