<template>
    <section id="header">
        <div>
            <!-- <video :src="videoPath" playsinline loop muted autoplay width="100%" height="100%" style="position: relative; z-index: 1;"></video> -->
            <video 
                v-if="itemsHome.home_main_banner"
                class="my_mt_vid"
                :src="itemsHome.home_main_banner"
                loop
                muted
                autoplay
            ></video>

            <!-- Imágenes -->
            <img class="my_img_b_1" src="@/assets/img/banner_img_1.png" width="50%" style="position: absolute; left: 0%; top: 0%; z-index: 2;" />
            <img class="my_img_b_2" src="@/assets/img/banner_img_2.png" style="position: absolute; right: 0%; z-index: 2;" />
        </div>
    </section>
</template>

<script>
import { mapState } from "vuex";
export default {
    data: () => ({
        // videoPath: require('@/assets/video/banner_inicio.mp4')
    }),
    computed:{
        ...mapState("loader", ["loading"]),
        ...mapState("landing", ["itemsHome"]),
    },
}
</script>

<style>
section {
  position: relative;
}

.my_mt_vid {
    position: relative;
    width: 100%;
    padding: 0;
    z-index: 1;
}

.my_img_b_1 {
    top: 9% !important;
    left: 5% !important;
    max-width: 355px;
    max-height: 250px;
}

.my_img_b_2 {
    top: 5% !important;
    max-width: 435px;
}

/* Media Queries para dispositivos móviles */
/* Small to large phone */
@media only screen and (max-width: 599px) {
    .my_mt_vid {
        margin-top: 0% !important;
    }
    .my_img_b_1 {
        left: 2% !important;
        top: 30% !important;
        max-width: 225px;
        max-height: 130px;
    }
    .my_img_b_2 {
        top: 20% !important;
        max-width: 200px;
    }
}

/* Small to medium tablet */
@media only screen and (min-width: 600px) and (max-width: 959px) {
    .my_img_b_1 {
        left: 2% !important;
        top: 30% !important;
        max-width: 225px;
        max-height: 130px;
    }
    .my_img_b_2 {
        top: 20% !important;
        max-width: 200px;
    }
}

/* Large tablet to laptop */
@media only screen and (min-width: 960px) and (max-width: 1263px) {
    .my_img_b_1 {
        left: 2% !important;
        top: 20% !important;
        max-width: 225px;
    }
    .my_img_b_2 {
        top: 20% !important;
        max-width: 240px;
    }
}

/* Desktop */
@media only screen and (min-width: 1264px) and (max-width: 1903px) {
  /* Estilos para ordenadores de escritorio */
}

/* 4k and ultra-wide */
@media only screen and (min-width: 1904px) {
  /* Estilos para dispositivos 4k y ultrapanorámicos */
}


</style>
