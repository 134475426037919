<template>
    <v-container class="pt-5">
        <v-row >
            <v-col cols="12" sm="12" md="12" class="text-center">
                <v-img class="mx-auto" max-width="350" src="~@/assets/img/home_simulador.png"></v-img>
            </v-col>
        </v-row>
        <v-row justify="space-around">
            <v-col cols="12" sm="12" md="4" class="text-simulador">
                <p 
                    v-if="itemsHome.home_main_text_1 != ''"
                    class="text-h5 font-weight-light blue-grey--text text--darken-4"
                >
                    {{ itemsHome.home_main_text_1 }}
                </p>
                <p
                    v-if="itemsHome.home_main_text_2 != ''"
                    class="text-h5 font-weight-light blue-grey--text text--darken-4"
                >
                {{ itemsHome.home_main_text_2 }}
                </p>
                <p
                    v-if="itemsHome.home_main_text_3 != ''"
                    class="text-h4 font-weight-bold" style="color: #557199"
                >
                    {{ itemsHome.home_main_text_3 }}
                </p>
                <p
                    v-if="itemsHome.home_main_text_4 != ''"
                    class="text-h5 font-weight-light blue-grey--text text--darken-4"
                >
                    {{ itemsHome.home_main_text_4 }}
                </p>
                <p
                    v-if="itemsHome.home_main_text_5 != ''"
                    class="text-h5 font-weight-light blue-grey--text text--darken-4"
                >
                    {{ itemsHome.home_main_text_5 }}
                </p>
                <p 
                    v-if="itemsHome.home_main_text_6 != ''"
                    class="text-subtitle-1 font-weight-light text-justify blue-grey--text text--darken-4"
                >
                    {{ itemsHome.home_main_text_6 }}
                </p>
                <!-- <p>
                    <pre>
                        {{ itemsHome.home_main_simulator_data }}
                    </pre>
                    
                    <pre>
                        {{ currentValores }}
                    </pre>
                </p> -->
            </v-col>
            <v-col cols="12" sm="12" md="7">
                <!-- <pre>
                    {{  simulador }}
                </pre> -->
                <v-form ref="frmSimulador" v-model="frmValidated.frmSimulador">
                    <v-row>
                        <v-col cols="12" sm="12" md="12">
                            <v-row>
                                <v-col cols="12" sm="12" md="4">
                                    <v-row align="center" justify="space-around" class="pb-3">
                                        <h4 class="secondary--text">Monto a financiar</h4>
                                    </v-row>
                                    <v-text-field
                                        v-model="simulador.montoInicial"
                                        filled
                                        dense
                                        rounded
                                        prefix="$"
                                        
                                        type="number"
                                        required
                                        :loading="loading"
                                        :rules="[
                                            numeroPositivoMayorCero('Monto a financiar', simulador.montoInicial),
                                            maxCant('Monto a financiar', simulador.montoInicial, simulador.montoMax),
                                        ]"
                                        @keypress="soloNumeros"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12" sm="12" md="6">
                                    <v-row align="center" justify="space-around">
                                        <h4 class="secondary--text">Plazo</h4>
                                    </v-row>
                                    <v-row align="center" justify="space-around">
                                        <v-btn
                                            dark
                                            rounded
                                            :color="simulador.btnPlazo === 3 ? 'accent': 'secondary'"
                                            class="mt-2"
                                            :class="{ 'primary--text': simulador.btnPlazo === 3 }"
                                            :loading="loading"
                                            @click="setPlazos(3)"
                                        >
                                            3 meses
                                        </v-btn>
                                        <v-btn
                                            dark
                                            rounded
                                            :color="simulador.btnPlazo === 6 ? 'accent': 'secondary'"
                                            class="mt-2"
                                            :class="{ 'primary--text': simulador.btnPlazo === 6 }"
                                            :loading="loading"
                                            @click="setPlazos(6)"
                                        >
                                            6 meses
                                        </v-btn>
                                        <v-btn
                                            dark
                                            rounded
                                            :color="simulador.btnPlazo === 12 ? 'accent': 'secondary'"
                                            class="mt-2"
                                            :class="{ 'primary--text': simulador.btnPlazo === 12 }"
                                            :loading="loading"
                                            @click="setPlazos(12)"
                                        >
                                            12 meses
                                        </v-btn>
                                    </v-row>
                                </v-col>
                            </v-row>

                            <v-row class="py-4">
                                <v-col cols="12" sm="12" md="4">
                                    <v-row align="center" justify="space-around" class="pb-3">
                                        <h4 class="secondary--text">Frecuencia</h4>
                                    </v-row>
                                    <v-select
                                        v-model="simulador.selectFrecuencia"
                                        item-text="text"
                                        item-value="value"
                                        filled
                                        dense
                                        rounded
                                        :rules="listaRequerida('Frecuencia', simulador.selectFrecuencia)"
                                        :items="itemsFrecuencia"
                                        :loading="loading"
                                        @change="validarPlazos(simulador.selectFrecuencia)"
                                    ></v-select>
                                </v-col>
                                <v-col cols="12" sm="12" md="6">
                                    <v-row align="center" justify="space-around" class="pb-3">
                                        <h4 class="secondary--text">Tipo de procedimiento</h4>
                                    </v-row>
                                    <v-select
                                        v-model="simulador.tipoProcedimiento"
                                        item-text="text"
                                        item-value="value"
                                        filled
                                        dense
                                        rounded
                                        :rules="listaRequerida('Tipo de procesimiento', simulador.tipoProcedimiento)"
                                        :items="itemsTipoProcedimiento"
                                        :loading="loading"
                                    ></v-select>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="12" sm="12" md="4">
                                    <v-row class="pb-3">
                                        <h4 class="secondary--text">{{ txtCuotas || 0}} </h4>
                                    </v-row>
                                    <v-row class="pb-3">
                                        <h1 class="secondary--text">$ {{ simulador.pagoFijo ||0 }}</h1>
                                    </v-row>
                                </v-col>
                                <v-col cols="12" sm="12" md="6">
                                    <v-btn
                                        dark
                                        rounded
                                        block
                                        class="customButton"
                                        :loading="loading"
                                        @click="showDialog = true"
                                    >
                                        SOLICITAR CRÉDITO
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-form>
            </v-col>
        </v-row>

        <v-dialog v-model="showDialog" transition="dialog-top-transition" color="transparent" max-width="800">
            <v-card flat>
                <v-toolbar color="primary" dark>
                
                <v-spacer></v-spacer>
                <v-btn color="white" icon @click="showDialog = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
                <!-- 
                <v-card-text>
                </v-card-text>
                <v-card-actions>
                </v-card-actions> -->
                <v-img
                    src="@/assets/img/PUP-UP-INICIO.png"
                    
                    class="grey darken-4"
                    max-height="700"
                    
                ></v-img>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { mapState } from "vuex";
import { 
    requerido,
    txtRequerido,
    maxlength,
    soloNumeros,
    listaRequerida,
    validarValorMinimo,
    numeroPositivoMayorCero,
    maxCant
} from "@/utils/customRules";

import {
    numberWithCommas
} from "@/utils/customFunctions";

export default {
    data: () => ({
        showDialog: false,
        simulador: {
            montoMax: 0,
            montoInicial: 0,
            interesAnual: 0,
            anhos: 1,
            numTotalCuotas: 0,
            
            frecuenciaPago: 0,
            tasaMensual: 0,
            pagoFijo: 0,
            
            selectFrecuencia: 2,
            tipoProcedimiento: 1
        },
        currentValores: [],
        itemsFrecuencia: [
            {
                value: 1,
                text: "Quincenal",
            },
            {
                value: 2,
                text: "Mensual",
            }
        ],
        itemsTipoProcedimiento: [
            {
                value: 1,
                text: "No quirúrgico / En consultorio",
            },
            {
                value: 2,
                text: "Quirúrgico",
            }
        ],
        frmValidated: {
            frmSimulador: false,
        }
    }),
    computed: {
        ...mapState("loader", ["loading"]),
        ...mapState("landing", ["itemsHome"]),
        txtCuotas() {
            return this.simulador.selectFrecuencia == 1 ? 
                `${this.simulador.numTotalCuotas} quincenas de` : 
                `${this.simulador.numTotalCuotas} mensualidades de`;
        }
    },
    methods: {
        setPlazos(plazo) {
            console.log("setPlazos: ", plazo);
            this.simulador.btnPlazo = plazo;
            this.simulador.numTotalCuotas = this.simulador.selectFrecuencia == 1 ? plazo*2 : plazo;
            this.iniciarValores();
        },
        validarPlazos() {
            if(this.simulador.btnPlazo > 0) {
                this.simulador.numTotalCuotas = this.simulador.selectFrecuencia == 1 ? this.simulador.btnPlazo*2 : this.simulador.btnPlazo;
                this.iniciarValores();
            }else{
                this.$toast.info(
                    `Selecciona un plazo`
                );
            }
        },
        iniciarValores() {
            if (this.$refs.frmSimulador.validate()) {
                if(this.simulador.btnPlazo > 0) {
                    this.simulador.montoInicial = parseFloat(this.simulador.montoInicial);
                    this.simulador.interesAnual = parseFloat(this.simulador.interesAnual);

                    let result = this.calcularCuotas(
                        this.simulador.montoInicial,
                        this.simulador.interesAnual,
                        this.simulador.anhos,
                        this.simulador.numTotalCuotas
                    );

                    // console.table(result.tablaPagos);
                    // console.log(result.pagoFijo);
                    
                    this.simulador.pagoFijo = result.pagoFijo;
                }else{
                    this.$toast.info(
                        `Selecciona un plazo`
                    );
                }
            }else {
                this.$toast.warning(
                    `Algunos datos no son válidos`
                );
            }

        },
        calcularCuotas(montoInicial, interesAnual, anhos, frecuenciaPago) {
            // Calcular el número total de pagos
            let totalPagos = anhos * frecuenciaPago;

            // Calcular la tasa de interés mensual
            let tasaMensual = (interesAnual/100) / 12;

            // Calcular el pago fijo utilizando la fórmula de PAGO en Excel
            let pagoFijo = this.pago(tasaMensual, totalPagos, montoInicial);

            // Formatear el pago fijo con comas de miles
            pagoFijo = pagoFijo.toLocaleString('es-MX', {minimumFractionDigits: 2, maximumFractionDigits: 2});

            // Crear una matriz para almacenar los detalles de los pagos
            let tablaPagos = [];

            console.log(`Datos recibidos: ${montoInicial}, ${interesAnual}, ${anhos}, ${frecuenciaPago}`);
            // Agregar la primera fila con el monto del préstamo inicial
            tablaPagos.push({
                numeroPago: 0,
                pagoFijo: "",
                interes: "",
                capitalAmortizado: "",
                montoPrestamo: montoInicial.toFixed(2)
            });

            // Calcular los pagos y llenar la tabla de pagos
            let saldoPendiente = montoInicial;
            for (let i = 1; i <= totalPagos; i++) {
                // Calcular el interés para este pago
                let interesPago = saldoPendiente * tasaMensual;

                // Calcular el capital amortizado para este pago
                let capitalAmortizado = pagoFijo - interesPago;

                // Actualizar el saldo pendiente después del pago
                saldoPendiente -= capitalAmortizado;

                // Agregar los detalles de este pago a la tabla
                tablaPagos.push({
                    numeroPago: i,
                    pagoFijo: pagoFijo,
                    interes: interesPago.toFixed(2),
                    capitalAmortizado: (capitalAmortizado + interesPago).toFixed(2), // Corregir el cálculo del capital amortizado
                    montoPrestamo: saldoPendiente >= 0 ? saldoPendiente.toFixed(2) : 0 // Ajuste aquí para evitar montos negativos
                });
            }

            return {tablaPagos, pagoFijo};
        },
        pago(tasa, numPeriodos, prestamo) {
            let pago = prestamo * tasa / (1 - Math.pow(1 + tasa, -numPeriodos));

            return pago;
        },
        getInterestRate() {
            const amount = parseFloat(this.simulador.montoInicial);

            for (let rate of this.currentValores) {
                const min = parseFloat(rate.min_amount);
                const max = parseFloat(rate.max_amount);

                if (amount >= min && amount <= max) {
                    this.simulador.interesAnual = rate.interest_rate;
                    this.simulador.montoMax = rate.max_amount;
                    return;
                }
            }

            // // Si no encuentra una tasa de interés para el monto ingresado
            // this.simulador.interesAnual = 0;
        },
        requerido,
        txtRequerido,
        maxlength,
        soloNumeros,
        listaRequerida,
        validarValorMinimo,
        numeroPositivoMayorCero,
        maxCant,
        numberWithCommas
    },
    watch: {
        itemsHome: {
            handler(newVal) {
                try {
                    if (newVal) {
                        let index = newVal.home_main_simulator_data.length-1;
                        this.currentValores = newVal.home_main_simulator_data;
                        this.simulador.montoInicial = newVal.home_main_simulator_data[index].max_amount;
                        this.simulador.montoMax = newVal.home_main_simulator_data[index].max_amount;
                        this.simulador.interesAnual = newVal.home_main_simulator_data[index].interest_rate;
                        this.simulador.anhos = newVal.home_main_simulator_data[index].anhos;
                    }
                } catch (error) {
                    console.log(error);
                }
            },
            immediate: true // Ejecutar el handler inmediatamente al inicio
        },
        'simulador.montoInicial': {
            handler(newVal) {
                if (newVal) {
                    this.getInterestRate();
                    console.log('nuevo valor: ', newVal);
                }
            },
            immediate: false // Ejecutar el handler inmediatamente al inicio
        }
    }
}
</script>

<style scoped>
.text-simulador .text-h5 {
    line-height: 1.2rem !important;
}

.text-simulador .text-h3{
    line-height: 2.5rem !important;
}
</style>