<template>
    <div>
      <customHeader></customHeader>
  
      <v-app>
        <navigation :color="color" :flat="flat" :textColor="textColor" :logoCompleto="logoCompleto" />
        <v-main>
          <simulador></simulador>
          <tratamientos :showHeaderImg="true"></tratamientos>
          <faq></faq>
        </v-main>
        <v-scale-transition>
          <v-btn
            fab
            v-show="fab"
            v-scroll="onScroll"
            dark
            fixed
            bottom
            right
            color="secondary"
            @click="toTop"
          >
            <v-icon>mdi-arrow-up</v-icon>
          </v-btn>
        </v-scale-transition>
        <customFooter></customFooter>
      </v-app>
    </div>
  </template>
  
  <script>
  import { mapActions } from "vuex";

  import navigation from "@/modules/landing/components/Navigation.vue";
  import customHeader from "@/modules/landing/components/CustomHeader.vue";
  import simulador from "@/modules/landing/components/Simulador.vue";
  import tratamientos from "@/modules/landing/components/Tratamientos.vue";
  import faq from "@/modules/landing/components/FAQ.vue";
  import customFooter from "@/modules/shared/layouts/Footer.vue";
  
  export default {
    name: "LandingLayout",
  
    components: {
      customHeader,
      navigation,
      simulador,
      tratamientos,
      faq,
      customFooter,
    },
  
    data: () => ({
      logoCompleto: null,
      fab: null,
      color: "primary",
      textColor: "white",
      flat: null,
    }),
  
    async created() {
      await this.getItemsHome();
      await this.getItemsTreatments();

      const top = window.pageYOffset || 0;
      if (top <= 60) {
        this.color = "primary";
        this.flat = true;
      }
    },
  
    watch: {
      fab(value) {
        if (value) {
          this.color = "dark";
          this.textColor = "white";
          this.flat = false;
          this.logoCompleto = false;
        } else {
          this.color = "primary";
          this.textColor = "white";
          this.flat = true;
          this.logoCompleto = true;
        }
      },
    },
  
    methods: {
      ...mapActions({
        getItemsHome: "landing/getItemsHome",
        getItemsTreatments: "landing/getItemsTreatments",
      }),
      onScroll(e) {
        if (typeof window === "undefined") return;
        const top = window.pageYOffset || e.target.scrollTop || 0;
        this.fab = top > 60;
      },
      toTop() {
        this.$vuetify.goTo(0);
      },
    },
  };
  </script>
  
  <style scoped>
  #app {
    background-image: url("~@/assets/img/home_background.png");
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    z-index: 10;
  }
  </style>