import { DateTime } from 'luxon';

const EMAIL_REGEX = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/;
const COORDENADAS_REGEX = /^-?\d{1,2}(\.\d{1,15})?,\s*-?\d{1,3}(\.\d{1,15})?$/;
const RFC_REGEX = /^[A-ZÑ&]{3,4}\d{6}[A-Z\d]{2}[A\d]$/;
const CURP_REGEX = /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/;
const DOMAIN_REGEX = /^https:\/\/([\w-]+\.)+[\w-]+(\/[\w- ./?%&=]*)?$/;

export const validarRFC = (v) => {
    const rfc = v.trim().toUpperCase();
    if (rfc.length !== 12 && rfc.length !== 13) {
      return `El RFC ${rfc} debe tener 12 o 13 caracteres.`;
    }

    return RFC_REGEX.test(rfc) || `El formato del RFC ${rfc} es incorrecto.`;
};

export const validarCoordenadas = (v) => {
    return COORDENADAS_REGEX.test(v) || "Coordenadas inválidas";
};

export const validarEmail = (v) => {
    const email = v.trim().toLowerCase();

    return EMAIL_REGEX.test(email) || `El formato del email ${email} es incorrecto.`;
};

export const validarDominioWeb = (v) => {
    const dominio = v.trim().toLowerCase(v)

    return DOMAIN_REGEX.test(dominio) || "El dominio no es correcto.";
}

// Rules
export function validarCurp(valor) {
    const reglas = [];
    if(!valor || valor.trim() === '' || !CURP_REGEX.test(valor)){
        reglas.push(`El formato de la CURP es incorrecto.`);  
    }
    return reglas;
}

export function requerido(campo, valor) {
    const reglas = [];
    if (valor === null) {
        reglas.push(`El campo ${campo} es obligatorio.`);
        return reglas;
    }
  
    return reglas;
}

export function txtRequerido(campo, valor) {
    const reglas = [];
    try {
        valor = valor || '';
        if (!valor || valor.trim() === '') {
          reglas.push(`El campo ${campo} es obligatorio.`);
        }
      
    } catch (error) {
        // eslint-disable-next-line no-console
        console.log(`txtRequerido - ${campo}: `, error);
    }
    return reglas;
}

export function listaRequerida(campo, valor) {
    const reglas = [];
    valor = valor || 0;
    if (!valor || valor === 0) {
      reglas.push(`Elija un elemento del campo ${campo}.`);
    }
  
    return reglas;
}

export function telRequerido(campo, valor) {
    const reglas = [];
    valor = valor || '';
    if (!valor || valor.trim() === '') {
        reglas.push(`El campo ${campo} es obligatorio.`);
    } else if (valor.length !== 10) {
        reglas.push(`El campo ${campo} debe tener exactamente 10 caracteres.`);
    }
  
    return reglas;
}

export function maxlength(campo, valor, maxlength) {
    const reglas = [];
    if (!valor || valor.length > maxlength) {
      reglas.push(`El campo ${campo} debe tener como máx. ${maxlength} caracteres.`);
    }
  
    return reglas;
}

// Funciones
export function soloNumeros(evt){
    evt = evt ? evt : window.event;
    let expect = evt.target.value.toString() + evt.key.toString();

    if (!/^[+]?[0-9]*\.?[0-9]*$/.test(expect)) {
        evt.preventDefault();
    } else {
        return true;
    }
}

export function formatearFecha(inputFecha, formatoDestino = 'yyyy-MM-dd') {
    const fechaFormateada = DateTime.fromISO(inputFecha).toFormat(formatoDestino);
    // console.log("formatearFecha: ", {
    //     fecha: inputFecha,
    //     fechaFormateada: fechaFormateada
    // });
    return fechaFormateada;
}

export function calcularFecNacimiento(curp) {
    if (curp || curp.length == 18) {       
        const anioActual = DateTime.local().year;
        const anio =
            parseInt(curp.substring(4, 6)) + 2000 > anioActual
            ? parseInt(curp.substring(4, 6)) + 1900
            : parseInt(curp.substring(4, 6)) + 2000;

        const mes = parseInt(curp.substring(6, 8));
        const dia = parseInt(curp.substring(8, 10));

        if(!isNaN(anio) && !isNaN(mes) && !isNaN(dia)){
            const fechaNacimiento = DateTime.fromObject({
                year: anio,
                month: mes,
                day: dia
            });
            
            // console.log("Fecha: ", fechaNacimiento);
            return fechaNacimiento.toFormat('yyyy-MM-dd');
        }else{
            // console.log("curp no válida: ", {
            //     year: anio,
            //     month: mes,
            //     day: dia
            // });
            return "";    
        }
    }else{
        // console.log("curp no válida: ", curp);
        return "";
    }
}

export function validarValorMinimo(campoMin, valorMin, campoMax, valorMax) {
    const reglas = [];

    valorMin = parseInt(valorMin || 0);
    valorMax = parseInt(valorMax || 0);

    if(valorMin <= 0) {
        reglas.push(`El campo ${campoMin} debe ser mayor a cero.`);
    }
    if(valorMin >= valorMax) {
        reglas.push(`El campo ${campoMin} no puede ser mayor o igual al campos ${campoMax} (${valorMax}).`);
    }
  
    return reglas;
}

export function numeroPositivo(campo, value) {
    return value >= 0 || `El valor de ${campo} debe ser un número positivo`;
}

export function numeroPositivoMayorCero(campo, value) {
    return value > 0 || `El valor de ${campo} debe ser un número positivo mayor a cero`;
}

export function maxCant(campo, value, maxValue) {
    return value <= maxValue || `El valor de ${campo} no debe exceder ${maxValue}`;
    // return val => {
    // };
}